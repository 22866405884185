export default [
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/radnetimaging"
    },
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/RadNetImaging"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@radnetimaging"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/radnetimaging"
    },
    {
        "fa": "fab fa-pinterest",
        "value": "Pinterest",
        "linkTo": "https://www.pinterest.com/RadNetImaging/"
    },
    {
        "fa": "fab fa-youtube",
        "value": "YouTube",
        "linkTo": "https://www.youtube.com/RadNetVideos/"
    },
    {
        "fa": "fab fa-tiktok",
        "value": "TikTok",
        "linkTo": "https://www.tiktok.com/@radnetimaging"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/14101"
    },
    {
        "fa": "fas fa-globe",
        "value": "Enhanced Breast Cancer Detection (EBCD)",
        "linkTo": "https://myebcdmammo.com"
    },
    {
        "fa": "fas fa-globe",
        "value": "MammogramNow",
        "linkTo": "https://breastlink.com/services/mammogramnow"
    },
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://www.radnet.com/los-angeles/blog"
    },
    {
        "fa": "fas fa-map-marker-alt",
        "value": "Our Locations",
        "linkTo": "https://www.radnet.com/imaging-centers/california"
    },
    {
        "fa": "fas fa-user-md",
        "value": "RadNet Prostate",
        "linkTo": "https://prostate.radnet.com"
    },
    {
        "fa": "fas fa-hand-holding-medical",
        "value": "Choice RadNet",
        "linkTo": "https://choice.radnet.com/"
    },
    {
        "fa": "fa fa-briefcase",
        "value": "RadNet Careers",
        "linkTo": "https://careers.radnet.com/careers-home"
    },
    {
        "fa": "fas fa-female",
        "value": "#MaddyTheMammoCoach",
        "linkTo": "https://breastlink.com/maddy/"
    },
    {
        "fa": "fas fa-play-circle",
        "value": "RadNet TV",
        "linkTo": "https://www.radnet.tv"
    },
    {
        "fa": "fas fa-globe",
        "value": "XRayHours.com",
        "linkTo": "https://xrayhours.com"
    },
    {
        "fa": "fas fa-lungs",
        "value": "Lung Cancer Screening",
        "linkTo": "https://radnetimaging.com/low-dose-lung-ct-scan/"
    }
]